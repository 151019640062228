// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beauty-js": () => import("./../src/pages/beauty.js" /* webpackChunkName: "component---src-pages-beauty-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hair-js": () => import("./../src/pages/hair.js" /* webpackChunkName: "component---src-pages-hair-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-breeze-js": () => import("./../src/pages/locations/breeze.js" /* webpackChunkName: "component---src-pages-locations-breeze-js" */),
  "component---src-pages-locations-storm-js": () => import("./../src/pages/locations/storm.js" /* webpackChunkName: "component---src-pages-locations-storm-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

